import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Paper,
} from "@mui/material";

import { User } from "../../models/User";
import { supabase } from "../../third-party/supabaseClient";
import { PasteItem } from "../../models/PasteItem";
import Footer from "../../components/footer/Footer";
import { searchByUUID } from "../../services/fetchValues";

const App: React.FC = () => {
  const [text, setText] = useState<string>("");
  const [key, setKey] = useState<string>("");
  // const [user, setUser] = useState<User>();
  const [pasteItems, setPasteItems] = useState<PasteItem[]>();
  const [disabled, setDisabled] = useState(true);
  const [showSearchKeyForm, setShowSearchKeyForm] = useState(true);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };
  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKey(e.target.value);
  };

  // const fetchUser = async () => {
  //   const { data, error } = await supabase
  //     .from("pg_user")
  //     .select("*")
  //     .eq("user_id", "")
  //     .single();
  //   if (error) {
  //     console.error("Error fetching user:", error);
  //   } else {
  //     setUser(data as User);
  //   }
  // };

  const findKey = async () => {
    try {
      let item = await searchByUUID(key);
      if (item.data.is_subscribed === true) {
        setDisabled(false);
        setShowSearchKeyForm(false);
      }
    } catch (error) {
      setDisabled(true);
      setShowSearchKeyForm(true);
    }
  };

  const fetchPasteItems = async () => {
    const { data, error } = await supabase
      .from("pg_paste_item")
      .select("*")
      .order("created_at", { ascending: false });
    if (error) {
      console.error("Error fetching user:", error);
    } else {
      setPasteItems(data as PasteItem[]);
    }
  };

  const saveText = async () => {
    try {
      await supabase.from("pg_paste_item").insert([
        {
          text: text,
          user_id: key,
          is_subscribed: true,
          is_on_trial_version: false,
        },
      ]);
    } catch (error) {
      console.error(error);
      alert("Failed to save text");
    }
  };

  return (
    <Container maxWidth="sm">
      {showSearchKeyForm && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Align items vertically in the center
            justifyContent: "flex-start", // Align items in a row starting from the left
            marginTop: 5,
          }}
        >
          <Box marginRight={2}>
            <Typography variant="h5" align="center" gutterBottom>
              Enter your key
            </Typography>
          </Box>

          <TextField
            label="Enter Key"
            variant="standard"
            value={key}
            fullWidth
            onChange={handleKeyChange}
            sx={{
              mb: 2,
              "& .MuiInputBase-input": {
                textAlign: "center", // Center the placeholder and input text
              },
            }} // Add space between TextField and Button
          />

          <Button variant="outlined" color="primary" onClick={findKey}>
            Find Key
          </Button>
        </Box>
      )}
      <Box mt={5} mb={10}>
        <Typography variant="h5" align="center" gutterBottom>
          {/* Type some text below to access it on a browser on any other computer
          or device elsewhere... */}
        </Typography>
        <Box mt={3}>
          <TextField
            label="Enter Text"
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            maxRows={4000}
            value={text}
            onChange={handleInputChange}
          />
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={saveText}
            sx={{ marginRight: 2 }}
            disabled={disabled}
          >
            Save Text
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={fetchPasteItems}
            disabled={disabled}
          >
            View Pasted Text
          </Button>
        </Box>
        {pasteItems &&
          pasteItems.map((item, index) => (
            <Box mt={4} key={index}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">Pasted Item: {index + 1}</Typography>
                <Typography>{item.text}</Typography>
              </Paper>
            </Box>
          ))}
      </Box>
      <Footer />
    </Container>
  );
};

export default App;
