// services/supabaseService.ts
import { supabase } from "../third-party/supabaseClient";

export const searchByUUID = async (uuid: string) => {
  try {
    // Assuming the table is named 'pg_user' and has a 'user_id' column
    const { data, error } = await supabase
      .from("pg_user")
      .select("*")
      .eq("user_id", uuid) // Search by UUID
      .single(); // Return a single row

    if (error) {
      console.error("Error fetching record:", error.message);
      return { error: error.message };
    }

    return { data };
  } catch (err) {
    console.error("Error in searchByUUID:", err);
    return { error: "An unexpected error occurred" };
  }
};
